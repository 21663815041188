import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import SideColumnWrapper from "../side-column-wrapper/side-column-wrapper"
import {
  ExternalLink,
  ExternalLinkImageWrapper,
  SideColumnTitle,
  SideColumnText,
  body,
} from "./cta-product.module.css"
import { Link } from "gatsby"
import linkBuilder from "../../../modules/linkBuilder"
import { trackEvent } from "../../../modules/analytics"
import { eventTracking } from "../../../services/tracking"

const CtaProduct = ({ 
  cta,
  displayPosition,
  displayPageContentfulId,
  displayPageTitle,
  displayPagePath,
}) => {
  if (!cta.callToActionTitle) return null
  return (
    <SideColumnWrapper>
      <div className={ExternalLink}>
        <div className={body}>
          {cta.callToActionImage?.gatsbyImageData ? (
            <GatsbyImage
              image={cta.callToActionImage?.gatsbyImageData}
              className={ExternalLinkImageWrapper}
              alt={cta.callToActionImage?.title ?? ""}
            />
          ) : (
            <img
              src={cta.callToActionImage?.file?.url}
              className={ExternalLinkImageWrapper}
              alt={cta.callToActionImage?.title ?? ""}
            />
          )}

          <p className={SideColumnTitle}>{cta.callToActionTitle}</p>

          {cta.callToActionDescription?.childMarkdownRemark && (
            <p className={SideColumnText}>
              {cta.callToActionDescription.childMarkdownRemark.rawMarkdownBody}
            </p>
          )}
        </div>

        <Link to={linkBuilder.product(cta)} className={`download-form-submit`} onClick={() => {
          eventTracking("click", {
            contentfulId: cta.contentful_id,
            contentfulType: "product",
            contentfulTitle: cta.title,
            displayPageContentfulId,
            displayPageTitle,
            displayPosition,
            displayPagePath,
          })
          trackEvent("cta_sidenav_download_link")
        }}>
          {cta.callToActionButtonText ?? "Bekijk hier"}
        </Link>
      </div>
    </SideColumnWrapper>
  )
}

export default CtaProduct
